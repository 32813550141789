import { z } from 'zod';

export const VendaFormaPagamentoParcelaSchema = z.object({
    vpp_idpk: z.number().nullish(),
    vpp_venda_forma_pagamento_idpk: z.number().nullish(),
    vpp_vencimento: z.string().nullish(),
    vpp_dias: z.number().nullish(),
    vpp_parcela: z.number().nullish(),
    vpp_valor: z.number().nullish(),
    vpp_ultima_alteracao: z.string().nullish(),
    vpp_financeiro_movimento_idpk: z.number().nullish(),
});

export type VendaFormaPagamentoParcela = z.infer<typeof VendaFormaPagamentoParcelaSchema>;
