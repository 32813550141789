import { fetchApi } from 'std/api2/fetchApi';
import type { DefaultParams } from 'std/api2/types';
import type { TipoConsulta } from 'std/types/enum';
import { createDefaultSchema } from 'std/zod';
import { type VendaOrigem, VendaSchema, type VendaTipo } from '../types/Venda';

export enum VendaGetParamsDataTipo {
    DataGeracao = 1,
    DataOrcamento = 2,
    DataPedido = 3,
    DataVenda = 4,
    DataBonificacao = 5,
}

export type VendaGetParams = Partial<
    DefaultParams & {
        data_tipo: VendaGetParamsDataTipo;
        data_inicio: string;
        data_fim: string;
        pesquisar: string;
        tipo: VendaTipo;
        numero: number;
        origem: VendaOrigem;
        acao: string;
        venda_externa_idpk: number;
        contrato_idpk: number;
        vendedor_idpk: number;
        cliente_idpk: number;
        cliente_cidade: string;
        marcadores: string;
        sistema_integrado_idpk: number;
        dispositivo_idpk: number;
        tipo_consulta: TipoConsulta;
    }
>;

const schema = createDefaultSchema(VendaSchema);

export function apiVendaGet({ idpk, params }: { idpk?: number; params?: VendaGetParams }) {
    return fetchApi({
        endpoint: 'Venda/Venda',
        method: 'get',
        idpk,
        params,
        schema,
    });
}
