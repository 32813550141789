import { createFileRoute, redirect } from '@tanstack/react-router';
import { zodValidator } from '@tanstack/zod-adapter';
import { VendaGetParamsDataTipo } from 'features/venda/api/apiVendaGet';
import { useVendaStore } from 'features/venda/store';
import { DefaultSearchParamsSchema } from 'std/api/types';
import { isObjectEmpty } from 'std/util';
import { z } from 'zod';

export const VendaFilterSchema = DefaultSearchParamsSchema.extend({
    pesquisar: z.string().optional(),
    data_tipo: z.nativeEnum(VendaGetParamsDataTipo).optional(),
    data_inicio: z.string().optional(),
    data_fim: z.string().optional(),
    cliente: z.object({ idpk: z.number(), nome: z.string() }).optional(),
    cliente_cidade: z.string().optional(),
    transportador: z.object({ idpk: z.number(), nome: z.string() }).optional(),
    vendedor: z.object({ idpk: z.number(), nome: z.string() }).optional(),
    marcadores: z.string().optional(),
    contrato_idpk: z.number().optional(),
});

export type VendaFilter = z.infer<typeof VendaFilterSchema>;

export const Route = createFileRoute('/a/venda/$tipo/')({
    validateSearch: zodValidator(VendaFilterSchema),
    beforeLoad: (ctx) => {
        if (isObjectEmpty(ctx.search)) {
            const { filter } = useVendaStore.getState();
            throw redirect({ to: '.', search: filter });
        }
    },
});
