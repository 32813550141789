export function getVendaDateColumn(tipo: string | null | undefined): string {
    let coluna = 'ven_data';

    switch (tipo) {
        case 'venda': {
            coluna = 'ven_data_venda';
            break;
        }
        case 'pedido': {
            coluna = 'ven_data_pedido';
            break;
        }
        case 'orçamento': {
            coluna = 'ven_data_orcamento';
            break;
        }
        case 'bonificação': {
            // ven_data_bonificacao não existe. é ven_data_venda mesmo.
            coluna = 'ven_data_venda';
            break;
        }
        default: {
            break;
        }
    }

    return coluna;
}

import type { VendaTipo } from 'features/venda/types/Venda';

export function isTipoVendaValido(value: string | null | undefined): boolean {
    return (
        value === 'pedido' || value === 'venda' || value === 'orçamento' || value === 'bonificação'
    );
}

export function isTipoVendaMasculino(value: VendaTipo | null | undefined): boolean {
    return value === 'orçamento' || value === 'pedido';
}

export function getCraseVendaTipo(value: VendaTipo | null | undefined): 'à' | 'ao' {
    return isTipoVendaMasculino(value) ? 'ao' : 'à';
}

export function isVendaCancelada(value: string | null | undefined): boolean {
    return (
        typeof value === 'string' &&
        ['Cancelada', 'Cancelado', 'Recusada', 'Recusado'].includes(value)
    );
}
