import { router } from 'router/Router';
import type { VendaFilter } from 'router/routes/a/venda.$tipo';
import { defaultSearchParams } from 'std/api/types';
import { isObjectEmpty } from 'std/util';
import { create } from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';

type VendaStore = {
    filter: VendaFilter;
    applyFilter: (newFilter: Partial<VendaFilter>) => void;
    clearFilter: () => void;
};

export const useVendaStore = create<VendaStore>()(
    persist(
        (set, get) => ({
            filter: defaultSearchParams,

            applyFilter(f) {
                const newFilter = { ...get().filter, ...f };

                if (isObjectEmpty(newFilter)) {
                    get().clearFilter();
                } else {
                    router.navigate({ to: '.', search: newFilter });
                    set({ filter: newFilter });
                }
            },

            clearFilter() {
                router.navigate({ to: '.', search: defaultSearchParams });
                set({ filter: defaultSearchParams });
            },
        }),
        {
            name: 'venda',
            partialize: (state) => ({ filter: state.filter }),
            storage: createJSONStorage(() => sessionStorage),
        },
    ),
);
