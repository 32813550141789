import type { ProdutoRegras } from 'features/produto/util';
import { z } from 'zod';

export const VendaItemSchema = z.object({
    vei_idpk: z.number().nullish(),
    vei_venda_idpk: z.number().nullish(),
    vei_quantidade: z.number().nullish(),
    vei_custo_medio: z.number().nullish(),
    vei_valor_custo: z.number().nullish(),
    vei_valor_unitario: z.number().nullish(),
    vei_valor_total: z.number().nullish(),
    vei_peso_bruto: z.number().nullish(),
    vei_peso_liquido: z.number().nullish(),
    vei_produto_idpk: z.number().nullish(),
    vei_produto_nome: z.string().nullish(),
    vei_produto_unidade: z.string().nullish(),
    vei_produto_codigo_interno: z.number().nullish(),
    vei_produto_sku: z.string().nullish(),
    vei_produto_pacote_barra_idpk: z.number().nullish(),
    vei_produto_cod_barra: z.string().nullish(),
    vei_regra_fiscal_idpk: z.number().nullish(),
    vei_desconto_valor: z.number().nullish(),
    vei_desconto_tipo: z.enum(['%', '$']).nullish(),
    vei_frete: z.number().nullish(),
    vei_seguro: z.number().nullish(),
    vei_outras_despesas: z.number().nullish(),
    vei_volume_qtde: z.number().nullish(),
    vei_detalhes: z.string().nullish(),
    vei_icmsst_valor: z.number().nullish(),
    vei_icmsst_fcp_valor: z.number().nullish(),
    vei_ipi_valor: z.number().nullish(),
    vei_ii_valor: z.number().nullish(),
    vei_ultima_alteracao: z.string().nullish(),
});

export type VendaItem = z.infer<typeof VendaItemSchema>;

export type VendaItemDetalhado = VendaItem & {
    produto: ProdutoRegras;
};
