import { z } from 'zod';

export enum AcaoTipo {
    NfeEmitida = 'N',
    NfeGerada = 'NG',
    NfceEmitida = 'C',
    NfceGerada = 'CG',

    FinanceiroLancadoVenda = 'FV',
    FinanceiroPrevistoVenda = 'FP',
    FinanceiroLancadoNfe = 'FN',
    FinanceiroLancadoNfce = 'FC',

    EstoqueMovimentadoVenda = 'EV',
    EstoqueMovimentadoBonificacao = 'EB',
    EstoqueMovimentadoNfe = 'EN',
    EstoqueMovimentadoNfce = 'EC',

    LinkCompartilhado = 'LC',
    LinkVisualizado = 'LV',

    RomaneioExpedicao = 'EX',
    ManifestoCarga = 'MC',
    VendaExterna = 'VE',
    Contrato = 'CT',
}

export const AcaoSchema = z.object({
    vea_idpk: z.number(),
    vea_empresa_idpk: z.number(),
    vea_ultima_alteracao: z.string(),
    vea_venda_idpk: z.number().nullable(),
    vea_nota_fiscal_idpk: z.number().nullable(),
    vea_nfce_idpk: z.number().nullable(),
    vea_data: z.string().nullable(),
    vea_tipo: z.nativeEnum(AcaoTipo),
    vea_tipo_descricao: z
        .string()
        .nullable()
        .transform((v) => v ?? ''),
});

export type Acao = z.infer<typeof AcaoSchema>;
